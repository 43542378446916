import { useEffect, useRef, useState } from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";

const AppUploadFileCroped = (props) => {
  const [fileList, setFileList] = useState([]);
  const [showFiles, setShowFiles] = useState(true);

  useEffect(() => {
    console.log({ AppUploadFileCroped: props });
    if (props.defaultValue?.length > 0) {
      const attachmentIds = props.defaultValue;

      let newFileList = attachmentIds?.map((obj) => {
        let fileObj = {
          name: decodeURIComponent(
            obj?.url?.substring(obj?.url?.lastIndexOf("/") + 1)
          ),
          uid: obj.attachment_id,
          url: obj.url,
        };
        return fileObj;
      });

      setFileList(newFileList);
    }
  }, [props.defaultValue?.length > 0]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    let fileId = file?.uid;
    // if (!src) {
    //   src = await new Promise((resolve) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file.originFileObj);
    //     reader.onload = () => resolve(reader.result);
    //   });
    // }
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);

    let authToken = window.store.getState().root.auth;
    console.log({ authToken: authToken, fileId, file });
    app.api
      .request(app.apis().fileDownloader)
      .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken;
  };

  const handleCustomRequest = ({ file, onSuccess, onError }) => {
    console.log(props?.checkFileSize, props?.maxsize);

    if (
      props?.checkFileSize &&
      file.size / 1024 / 1024 > Number(props?.maxsize)
    ) {
      message.error(
        `Cropped image must be smaller than ${Number(props?.maxsize)}MB!`
      );
      setShowFiles(false);
      setFileList([]);
    } else {
      setShowFiles(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_type", "package");

      fetch(`${envconfig[environment].api_base}/api/v1/content/upload/public`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          message.success("Upload successful");
          console.log({ res });

          file.attachment_id = res?.data?.attachment_id;
          file.thumbnail_url = res?.data?.thumbnail_url;
          setFileList((prevList) => [...prevList, file]); // Update file list
          onSuccess("ok");
        })
        .catch(() => {
          message.error("Upload failed");
          onError("Upload failed");
        });
    }
  };

  // Custom delete (remove) handler
  const handleRemove = async (file) => {
    const fileId = file.attachment_id || file.uid; // The unique file identifier

    try {
      await fetch(
        `${envconfig[environment].api_base}/api/v1/content/upload/${fileId}`,
        {
          method: "DELETE",
        }
      );

      message.success("File deleted successfully");
      setFileList((prevList) =>
        prevList.filter((item) => item.attachment_id !== fileId)
      );
    } catch (error) {
      message.error("Failed to delete file");
      return false; // Prevent removal from UI if deletion fails
    }
  };
  return (
    <ImgCrop
      aspect={50 / 50}
      quality={1}
      modalTitle="Crop Photo"
      modalOk="Save"
    >
      <Upload
        customRequest={handleCustomRequest}
        onRemove={(...args) => handleRemove(...args)}
        listType="text"
        maxCount={5}
        fileList={fileList}
        onChange={onChange}
        // onPreview={onPreview}
        className="crop_modal_btn"
        {...(showFiles === false
          ? {
              showUploadList: false,
            }
          : {
              showUploadList: { showRemoveIcon: !props?.disabled },
            })}
        // showUploadList={{ showRemoveIcon: !props?.disabled }}
        itemRender={(originNode, file) => {
          // console.log("File in itemRender:", file); // Debugging
          return (
            <div
              data-attachment-id={file.attachment_id || file.uid || ""}
              data-thumbnail-url={file.thumbnail_url || file.url || ""}
            >
              {originNode}
            </div>
          );
        }}
      >
        {fileList.length < 5 && "+ Upload"}
      </Upload>
    </ImgCrop>
  );
};
export default connect(AppUploadFileCroped);
