import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../../mixins";

export class AddSessionDetails extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddSessionDetails");
    this.state = {
      editData: {},
      projectEndDate: new Date(),
      classData: {},
      loader: false,
      formFields: [
        {
          type: "text",
          name: "session_name",
          label: "Session Name",
          maxLength: "150",
          required: true,
        },
        {
          type: "select",
          name: "sub_module_id",
          label: "Sub Module Name",
          placeholder: "select",
          options: [],
          required: true,
        },
        // {
        //   type: "select",
        //   name: "audience_type",
        //   label: "Audience Type",
        //   placeholder: "select",
        //   options: _enum.AUDIENCE_TYPES,
        //   required: true,
        // },
        {
          type: "textarea",
          name: "session_description",
          label: "Session Description (Max 250 words)",
          maxLength: "250",
          invalidmessage: "Value Should Not Exceed 250 Characters",
          controlClass: "!min-h-[150px]",
          fieldclass: "col-12",
          required: true,
        },
      ],
    };
    this.tablinks = mixins.tablinks;
  }

  get has_edit() {
    return !!this.props.urlparams.session_id;
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      // level: this.props.urlparams.level,
      // curriculum_id: this.props.urlparams.curriculum_id,
      // school_id: this.props.urlparams.school_id,
      // class_id: this.props.urlparams.class_id,
    };
  }

  getPillarParams() {
    return this.props.urlparams.type;
  }

  sumitForm(event, data) {
    console.log("add session: ", data);

    if (this.props.urlparams.session_id) {
      let payload = {
        ...data,
        session_id: this.props.urlparams.session_id,
      };
      for (let key in payload) {
        if (payload[key] === "") {
          delete payload[key];
        }
      }
      // this.api.apiUpdateSession(payload).then((res) => {
      //   console.log({ apiUpdateSession: res });
      //   let nextLink = `/contentmanagement/sessions/addvideos${Object.encodeuri(
      //     this.getGradeLevelParams()
      //   )}&type=${this.getPillarParams()}&session_id=${res.data.session_id}`;
      //   this.props.navigate(nextLink);
      // });
      this.api
        .apiUpdateSessionById(payload, {
          session_id: this.props.urlparams.session_id,
        })
        .then((res) => {
          console.log({ apiUpdateSessionById: res });
          let nextLink = `/contentmanagement/sessions/addvideos${Object.encodeuri(
            this.getGradeLevelParams()
          )}&type=${this.getPillarParams()}&session_id=${res.data.session_id}`;
          this.props.navigate(nextLink);
        });
    } else {
      let payload = {
        ...data,
      };
      for (let key in payload) {
        if (payload[key] === "") {
          delete payload[key];
        }
      }
      this.api.apiAddSession(payload).then((res) => {
        // console.log({ apiAddSession: res });
        let nextLink = `/contentmanagement/sessions/addvideos${Object.encodeuri(
          this.getGradeLevelParams()
        )}&type=${this.getPillarParams()}&session_id=${
          res.session_id
        }&${Object.QueryString({
          new: 1,
        })}`;
        this.props.navigate(nextLink);
      });
    }

    // let payload = {
    //   ...data,
    //   // school_id: this.props.urlparams.school_id,
    //   // class_id: this.props.urlparams.class_id,
    //   curriculum_id: this.props.urlparams.curriculum_id,
    //   category_type: this.props.urlparams.type.toUpperCase(),
    //   is_active : true
    // };

    // // console.log({ apiCreateSession: payload });

    // this.api.apiCreateSession(payload).then((res) => {
    //   // console.log({ apiCreateModule: res });
    //   let nextLink = `/contentmanagement/sessions/addvideos${Object.encodeuri(
    //     this.getGradeLevelParams()
    //   )}&type=${this.getPillarParams()}&session_id=${
    //     res.session_id
    //   }&${Object.QueryString({
    //     new: 1,
    //   })}`;
    //   this.props.navigate(nextLink);
    // });
    // }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchSubModule();
  }

  fetchData() {
    if (this.props.urlparams.session_id) {
      localStorage.setItem("session_id", this.props.urlparams.session_id);
      this.setState({ loader: true });
      this.props.api
        .apiGetContentSessionById({
          session_id: this.props.urlparams.session_id,
        })
        .then(({ data }) => {
          console.log({ editData: data });
          this.setState({
            editData: data,
            loader: false,
          });
        });
    }
  }

  fetchSubModule() {
    this.setState({ loader: true });
    let payload = {
      module_id: localStorage.getItem("module_id"),
      curriculum_id: localStorage.getItem("curriculum_id"),
    };
    this.props.api
      .apiGetSubModules(payload)
      .then((res) => {
        // console.log({apiGetSubModules: res.data});
        let classNames = this.state.formFields.find(
          (o) => o.name == "sub_module_id"
        );
        classNames.options = res.data[0]?.records?.options(
          "sub_module_id",
          "sub_module_name"
        );
        this.setState({
          formFields: [...this.state.formFields],
          loader: false,
        });
      })
      .catch((error) => {
        console.log({ error: error });
        this.setState({ loader: false });
      });
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `Content Management / ${
        this.props.urlparams.grade
      } Grade / Courses / ${this.props.urlparams.type.toTitleCase()} / Add Session`;
    } else {
      return "Content Management / Sessions";
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/contentmanagement/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}`;
      return backLink;
    } else {
      return "/contentmanagement/sessions";
    }
  }

  render() {
    let { tablinks, state, has_edit } = this;
    let { editData, formFields } = state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });
    // console.log({ formFields });
    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper
          title="Content Management"
          back={this.getBreadCums()}
          backlink={this.getBackLink()}
        >
          <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
          <div className="row m-auto">
            <div className="col"></div>
            <div className="mb-4"></div>
          </div>
          <div className="row m-auto">
            <AppForm
              className="flex flex-col gap-4 bg-white p-4"
              onSubmit={(...args) => this.sumitForm(...args)}
            >
              <div className="row">
                {formFields?.map((field, i) => (
                  <div
                    className={
                      field.fieldclass
                        ? field.fieldclass
                        : "col-12 col-md-6 col-lg-4"
                    }
                    key={"field" + i}
                  >
                    <AppInput
                      {...field}
                      {...(field.name === "session_id" &&
                      this.mixin.params().new
                        ? {
                            disabled: true,
                          }
                        : { disabled: this.props.urlparams.readonly })}
                    ></AppInput>
                  </div>
                ))}
              </div>
              <div className="row no-gutters">
                {this.props.urlparams.readonly ? (
                  <>
                    <Link
                      to={"/projectmanagement"}
                      className="btn btn_primary col col-md-1 ml-auto grey ml-auto me-2"
                    >
                      Cancel
                    </Link>
                    <a
                      href="#"
                      className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                    >
                      Edit
                    </a>
                    <button className="btn add-btn col col-md-1">Next</button>
                  </>
                ) : (
                  <>
                    <button className="btn add-btn col col-md-1 ml-auto">
                      Save & Next
                    </button>
                    <Link
                      to={`/contentmanagement/sessions?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&type=${this.props.urlparams.type}&curriculum_id=${this.props.urlparams.curriculum_id}`}
                      className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                    >
                      Cancel
                    </Link>
                  </>
                )}
              </div>
            </AppForm>
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(AddSessionDetails);
