export default {

    DIFFICULTY_LEVEL: [
        { key: "simple", label: "Simple" },
        { key: "medium", label: "Medium" },
        { key: "complex", label: "Complex" },
    ],
    QUESTION_TYPES: [
        // { key: "text", label: "Free Text" },
        { key: "checkbox", label: "Multi Select (Check Box)" },
        { key: "radio", label: "Single Select (Radio Button)" },
    ],
    MAP_LEVEL_GRADE: {
        A: "I",
        B: "II",
        C: "III",
        D: "IV",
        0: "V",
        1: "VI",
        2: "VII",
        3: "VIII",
        4: "IX",
        5: "X",
        6: "XI",
        7: "XII",
    },
}