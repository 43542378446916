import React, { Component } from "react";
export class PackageSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          name: "package_name",
          label: "Search by Name",
          type: "text",
        },
      ],
    };
  }

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  clearTextData = () => {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  };

  render() {
    let { fields } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-9" key={i}>
            <AppInputFocus
              {...field}
              onKeyDown={(e) => (e.key === "Enter" ? this.onSearch() : "")}
            ></AppInputFocus>
          </div>
        ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onSearch()}
          >
            Search
          </button>
        </div>
        <span className="clear-all" onClick={() => this.clearTextData()}>
          Clear Filter
        </span>
      </AppForm>
    );
  }
}

export default connect(PackageSearch);
