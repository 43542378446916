import axios from "axios";
import config from "./config";
window.axios = axios;
window.envconfig = config;
const environment = Object.entries(config).map(([key, val]) => ({ ...val, name: key })).find(obj => obj.base.split(',').includes(window.location.origin))?.name || 'production';
export const api = config[environment];
axios.defaults.headers.common["Authorization"] = config.static_token;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = config.api_base;
window.environment = environment;
export default api;