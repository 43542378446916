import cmMapcolumns from "./cm.mapcolumns";
export default {
  apiFileUploader(data, urldata = mixins.params()) {
    console.log("filedata", data);
    return this.request(this.api().fileuploader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileUploaderPublic(data, urldata = mixins.params()) {
    console.log("filedata", data);
    return this.request(this.api().fileuploaderpublic, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileRemover(data, urldata = mixins.params()) {
    return this.request(this.api().filedeleter, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileDownloader(data, urldata = mixins.params()) {
    return this.request(this.api().fileDownloader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiGetPptdata(data, urldata = {}) {
    return this.request(this.api().fileDownloader, data)
      .urltransform(data)
      .exec()
      .log()
      .get();
  },

  apiGetClasses(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    // const role = localStorage.getItem('currentAdminRole');
    // let endPointObj = role == 'SCHOOL_ADMIN' ? this.api().getCurriculumSchool : this.api().getClasses;
    let endPointObj = this.api().getClasses;
    return this.request(endPointObj) //, data
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetClasseById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getClassById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        console.log("getClassById: ", response);
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiAddClass(data) {
    console.log({ apiAddClass: data });
    let payload = {
      ...cmMapcolumns.mapPayload("class", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createClass, payload).exec().log().get();
  },

  apiChangeSessionAccess(data, urldata = { ...mixins.params() }) {
    let payload = {
      ...data,
      curriculum_id: urldata.curriculum_id,
      school_id: "1osdg2w6llnlnzpk",
      // session_id: "2yoiyi2rnsllm7v71m",
    };

    // console.log("urldata", urldata)

    return this.request(this.api().changeSessionAccess, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiCreateSession(data) {
    // console.log({ apiCreateSession: data });
    let payload = {
      ...cmMapcolumns.mapPayload("session", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createSession, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetFileMetaData(urldata) {
    return this.request(this.api().getFileMetaData)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetSessions(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolSessions
        : this.api().getAllSessions;

    return this.request(endPointObj, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        if (role == "SCHOOL_COORDINATOR") {
          console.log(response.data);

          return {
            data: response.data.map((record) =>
              cmMapcolumns.mapFields("session", record)
            ),
            total: 0,
          };
        }

        console.log(response.data[0].records);

        return {
          data: response.data[0].records.map((record) =>
            cmMapcolumns.mapFields("session", record)
          ),
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiGetSessionById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getSessionById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetSessions(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getSessions)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiUpdateSession(data) {
    console.log({ apiUpdateSession: data });

    let urldata = {
      session_id: data.session_id,
    };

    let payload = {
      // ...Object.except(cmMapcolumns.mapPayload('session', data), ["session_id", "session_name"])
      ...Object.except(data, ["session_id"]),
    };

    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateSession, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiUpdateSessionActive(payload) {
    let urldata = {
      ...mixins.params(),
      session_id: payload.session_id,
    };

    delete payload.session_id;

    return this.request(this.api().updateSession, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiDeleteSession(urldata = mixins.params()) {
    return this.request(this.api().deleteSession)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  // PPT

  apiGetAllPPTById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolPpts
        : this.api().getAllPPTById;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data.map((record) =>
            cmMapcolumns.mapFields("ppt", record)
          ),
          total: response.data.length || 0,
        };
      })
      .get();
  },

  apiDeletePPT(urldata = mixins.params()) {
    return this.request(this.api().deletePPT)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },



  apiDeleteHandout(urldata = mixins.params()) {
    return this.request(this.api().deleteHandout)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // ASSESSMENTS
  apiCreateAssessment(data, urldata = {}) {
    // console.log({ apiCreateAssessment: data });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createAssessment, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiStartAssessment(data, urldata = {}) {
    // console.log({ apiCreateAssessment: data });

    return this.request(this.api().startAssessment, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiGetAssessments(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolAssessments
        : this.api().getAssessments;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
          total: response.data.length || 0,
        };
      })
      .get();
  },
  getTableAssessmentPreviewById(data) {
    return this.request(this.api().getTableAssessmentPreviewById)
      .urltransform(data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetAssessmentById(data) {
    return this.request(this.api().getAssessmentById)
      .urltransform(data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiUpdateAssessment(data, urldata = {}) {
    // console.log({ apiUpdateHandout: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateAssessment, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiDeleteAssessment(urldata = mixins.params()) {
    return this.request(this.api().deleteAssessment)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiAddAssessmentQuestion(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    if (data.q_options) {
      payload = {
        ...data,
        q_options: data.q_options.split(",") || [],
      };
    }
    return this.request(this.api().addAssessmentQuestion, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiUpdateAssessmentQuestion(data, urldata = {}) {
    // console.log({ apiUpdateHandout: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    if (data.q_options) {
      payload = {
        ...data,
        q_options: data.q_options.split(",") || [],
      };
    }
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateAssessmentQuestion, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteAssessmentQuestion(urldata = mixins.params()) {
    return this.request(this.api().deleteAssessmentQuestion)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // VIDEO API CALLS
  apiCreateVideo(data) {
    return this.request(this.api().createVideo, [data])
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetVideosById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolVideos
        : this.api().getVideos;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data.map((record) =>
            cmMapcolumns.mapFields("ppt", record)
          ),
          total: response.data.length || 0,
        };
      })
      .get();
  },

  apiDeleteVideo(urldata = mixins.params()) {
    return this.request(this.api().deleteVideo)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // video progress
  apiRecordProgress(urldata = {}) {
    return this.request(this.api().recordProgress, urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  // STUDENT API CALLS
  apiSubmitAssessment(data) {
    return this.request(this.api().submitAssessment, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetAssessmentHistory(urldata = mixins.params()) {
    return this.request(this.api().getAssessmentHistory)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetSectionByClassId(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getSectionByClassId)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetUnselectedStudents(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getUnselectedStudents)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiAddSession(data) {
    // console.log({ apiAddSession: data });
    let payload = {
      ...cmMapcolumns.mapPayload("session", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addSessions, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiGetSessionsList(data) {

    return this.request(this.api().getSessionList, data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetStudentSessionsList(urldata) {

    return this.request(this.api().getStudentSessionList)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetContentSessionById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getcontentSessionById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetPreviewDetails(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getPreviewDetails)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response,
        };
      })
      .get();
  },

  apiUpdateSessionById(data) {
    let urldata = {
      session_id: data.session_id,
    };
    let payload = {
      ...Object.except(data, ["session_id"]),
    };
    return this.request(this.api().updateSessionById, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiGetContentBySession(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getContentBySession)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
          total: response.count || 0,
        };
      })
      .get();
  },

  apiCreateConetent(data) {
    return this.request(this.api().createConetent, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiUpdateConetent(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateConetent, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiRecordConentProgress(urldata = {}, data) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().recordContentProgress, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
};
