import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useEffect, useState, useRef } from "react";

const AppUploadFile = (props) => {
  const inputRef = useRef(null);
  const [filevalue, setFilevalue] = useState(null);
  const [fileobjvalue, setFileObjValue] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [showFiles, setShowFiles] = useState(true);
  useEffect(() => {
    if (uploadStatus !== "removed") {
      console.log({ props });

      if (props.defaultValue instanceof Array) {
        setFilevalue(props.defaultValue[0]?.attachment_id);
      } else if (props?.fileType === "package") {
        let fileObj = {
          ...(props.defaultValue?.url
            ? {
                name: decodeURIComponent(
                  props.defaultValue?.url?.substring(
                    props.defaultValue?.url?.lastIndexOf("/") + 1
                  )
                ),
              }
            : {}),
          uid: props.defaultValue?.attachment_id,
          url: props.defaultValue?.url,
        };
        setFileObjValue(fileObj);
        setFilevalue(props.defaultValue?.attachment_id);
        if (props?.fileReturnType && props.fileReturnType === "url") {
          inputRef.current.invalue = props.defaultValue?.url;
        } else {
          inputRef.current.invalue = props.defaultValue?.attachment_id;
        }
      } else {
        setFilevalue(props.defaultValue);
      }
    }
  }, [props.defaultValue]);
  const handleChange = (...args) => {
    let [info] = args;
    // To write code to accept only required formats
    // if (props?.accept) {
    //   if (!props?.accept.includes(info.file.type)) {
    //     return;
    //   }
    // }

    console.log({ file: info }, args);
    setUploadStatus(info.file.status);

    const maxSize = Number(props?.maxsize) * 1024 * 1024; // MB in bytes

    if (info.file.status !== "removed" && info.file.status !== "done") {
      console.log("UPLOADING");
      const resolve = (fileresponse) => {
        let attachmentId = fileresponse.data.attachment_id;
        let fileUrl = fileresponse.data.url;
        setFilevalue(attachmentId);
        console.log({ file: attachmentId, url: fileUrl });
        if (props?.fileReturnType && props.fileReturnType === "url") {
          inputRef.current.invalue = fileUrl;
        } else {
          inputRef.current.invalue = attachmentId;
        }
        loaded.close();
        props.onFileUploaded && props.onFileUploaded(attachmentId);
      };

      if (props?.checkFileSize && info.file && info.file.size > maxSize) {
        setShowFiles(false);
      } else {
        setFileObjValue(info.file);
        setShowFiles(true);
        let loaded = loader("Uploading file please wait....");
        if (props?.uploadType && props.uploadType === "public") {
          app.api
            .apiFileUploaderPublic({
              file: info.file.originFileObj,
              file_type: props.fileType || "school_document",
            })
            .then((fileresponse) => {
              resolve(fileresponse);
            })
            .catch(() => loaded.close());
        } else {
          app.api
            .apiFileUploader({
              file: info.file.originFileObj,
              file_type: props.fileType || "school_document",
            })
            .then((fileresponse) => {
              resolve(fileresponse);
            })
            .catch(() => loaded.close());
        }
        props.onChange && props.onChange(info.file);
      }
    }

    if (info.file.status === "removed") {
      console.log("REMOVED");

      const handleRemove = () => {
        loaded.close();
        inputRef.current.invalue = "";
        setFilevalue("");
        props.onChange && props.onChange("");
        props.onFileRemoved && props.onFileRemoved();
      };
      let loaded = loader("Deleting file please wait....");
      app.api
        .apiFileRemover(
          {
            file_type: props.fileType || "school_document",
            file_id: filevalue,
          },
          {
            attachmentId: filevalue,
          }
        )
        .then(handleRemove)
        .catch(handleRemove);
    }
  };
  let { multiple = false, maxCount = 1 } = props;

  const downloadFile = function (fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken, fileId });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < Number(props?.maxsize);
    if (!isLt2M) {
      error(`File must be smaller than ${Number(props?.maxsize)}MB!`);
      setShowFiles(true);
    } else {
      setShowFiles(false);
    }
    return isLt2M; // Return false to prevent upload if the file is too large
  };

  const attrs = {
    ...Object.except(props, ["className", "name", "onChange"]),
    onChange: props?.onChange || handleChange,
    ...(props?.checkFileSize
      ? {
          beforeUpload,
        }
      : {}),
    multiple,
    maxCount,
    ...(filevalue
      ? {
          fileList: filevalue
            ? [
                {
                  uid: "1",
                  name: fileobjvalue?.name || "File",
                  status: "done",
                  url: downloadFile(filevalue),
                  percent: 100,
                },
              ]
            : [],
        }
      : {}),
  };
  return (
    <Upload
      {...attrs}
      showUploadList={showFiles}
      customRequest={({ onSuccess }) => onSuccess("ok")}
    >
      <input
        ref={inputRef}
        type="hidden"
        className="filevalue_container"
        name={props.name}
        value={filevalue}
      ></input>
      <Button icon={<UploadOutlined />}>Upload File</Button>
    </Upload>
  );
};
export default connect(AppUploadFile);
