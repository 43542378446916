import devapis from "./devapis";
import stagingapis from "./stagingapis";
import prodapis from "./prodapis";
const localtodev = "http://localhost:3000,http://localhost:3001,";
const localtostage = "";
const localtoprod = "";

export const config = {
  "dev": {
    base: `${localtodev}https://dev.cognospace.in`,
    api_base: "https://devapi.cognospace.in",
    static_token: "",
    ...devapis,
    "open/login": {
      base: `${localtodev}https://dev.cognospace.in`,
      api_base: "https://devapi.cognospace.in",
      ...devapis,
    },
  },
  "staging": {
    base: `${localtostage}https://stagelogin.cognospace.in`,
    api_base: "https://stageapi.cognospace.in",
    static_token: "",
    ...stagingapis,

    "open/login": {
      base: `${localtostage}https://stagelogin.cognospace.in`,
      api_base: "https://stageapi.cognospace.in",
      ...stagingapis,
    },
  },
  "production": {
    base: `${localtoprod}https://login.cognospace.in`,
    api_base: "https://api.cognospace.in",
    static_token: "",
    ...stagingapis,

    "open/login": {
      base: `${localtoprod}https://login.cognospace.in`,
      api_base: "https://api.cognospace.in",
      ...stagingapis,
    },
  },
};

export default config;
