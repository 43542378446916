import contentmanagement from "./enums/contentmanagement.enum";
import curriculum from "./enums/curriculum.enum";
import schoolmanagement from "./enums/schoolmanagement.enum";

export default {
  contentmanagement: contentmanagement,
  schoolmanagement: schoolmanagement,
  curriculum: curriculum,
  FILEIDPAT:
    "^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$",
  MANDATORY_OBJS: {
    name: {
      name: "name",
      field_name: "Name",
      field_type: "text",
      field_constraint: "mandatory,character",
      possible_values: [],
    },
    email: {
      name: "email",
      field_name: "Email",
      field_type: "text",
      field_constraint: "mandatory,email",
      possible_values: [],
    },
    mobile_no: {
      name: "mobile_no",
      field_name: "Mobile No",
      field_type: "text",
      field_constraint: "mandatory,phonenumber",
      possible_values: [],
    },
  },
  MANDATORY_FIELDS: ["name", "email", "mobile_no"],
  APPLICANT_ROLES: [
    { key: "digital-sakhi", label: "Digital Sakhi" },
    { key: "manager", label: "Manager" },
    { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
    { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
    { key: "others", label: "Others" },
  ],

  APPLICANT_LANGUAGE_LIST: [
    { key: "ta", label: "Tamil" },
    { key: "te", label: "Telugu" },
    { key: "kn", label: "Kannada" },
    { key: "hi", label: "Hindi" },
    { key: "en", label: "English" },
  ],
  FIELD_TYPES: [
    { key: "text", label: "Free Text" },
    { key: "textarea", label: "Free Text Area" },
    { key: "select", label: "Dropdown" },
    { key: "checkbox", label: "Multi Select : Check Box" },
    { key: "radio", label: "Single Select : Radio Button" },
    { key: "file", label: "Upload" },
    { key: "date", label: "Calendar" },
  ],
  QUESTION_FIELD_TYPES: [
    { key: "text", label: "Free Text" },
    { key: "checkbox", label: "Multi Select : Check Box" },
    { key: "radio", label: "Single Select : Radio Button" },
    { key: "file", label: "Upload" },
    { key: "questionfile", label: "Upload Questions", inkey: "file" },
  ],
  FIELD_CONSTRAINTS: [
    { key: "numeric", label: "Numeric" },
    { key: "character", label: "Character" },
    { key: "phonenumber", label: "Phone Number" },
    { key: "email", label: "Email" },
    { key: "future_dates_only", label: "Future Dates Only" },
    { key: "past_dates_only", label: "Past Dates Only" },
    { key: "mandatory", label: "Mandatory" },
  ],
  CONSTRAINTS_MAP: {
    numeric: "^(0|[1-9][0-9]+)$",
    // character: "^[A-Za-z ]+$",
    character: "",
    email:
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
    phonenumber: "^[0-9]{10}$",
    mandatory: "^(.+)$",
    future_dates_only: (date) =>
      date.getTime() >= new Date().toDate().date().getTime(),
    past_dates_only: (date) =>
      date.getTime() <= new Date().toDate().date().getTime(),
  },
  APPLICANT_STATUS: [
    { key: "open", label: "Open" },
    { key: "selected", label: "Selected" },
    { key: "rejected", label: "Rejected" },
  ],
  ENQUIRY_STATUS: [
    { key: "OPEN", label: "Open" },
    { key: "CONTACTED", label: "Contacted" },
    { key: "INTERESTED", label: "Interested" },
    { key: "NOT_INTERESTED", label: "Not Interested" },
    { key: "CALL_NOT_ANSWERED", label: "Call Not Answered" },
    { key: "CALL_BACK", label: "Call Back" },
  ],
  INVOICE_STATUS: [
    { key: "NOT_PAID", label: "Not Paid" },
    { key: "PAID", label: "Paid" },
  ],
  SCHOOL_STATUS: [
    { key: "ACTIVE", label: "Active" },
    { key: "PENDING", label: "Pending" },
    { key: "DE_ACTIVE", label: "Deactive" },
  ],
  PRIORITY: [
    { key: "HIGH", label: "High", index: 1 },
    { key: "MEDIUM", label: "Medium", index: 2 },
    { key: "LOW", label: "Low", index: 3 },
  ],
  TASK_STATUS: [
    { key: "TODO", label: "To-Do", color: "text-blue" },
    { key: "INPROGRESS", label: "In-progress", color: "text-warning" },
    { key: "HOLD", label: "Hold", color: "text-danger" },
    { key: "COMPLETED", label: "Completed", color: "text-success" },
  ],
  EVENT_STATUS: [
    { key: "OPEN", label: "Scheduled" },
    { key: "CANCELLED", label: "Cancelled" },
    { key: "COMPLETED", label: "Completed" },
  ],
  DATE_FILTER: [
    { key: "ALL", label: "All", checked: true },
    { key: "1", label: "1 Days" },
    { key: "2", label: "2 Days" },
    { key: "2%2B", label: "More than 2 Days" },
  ],
  MONTHS: [
    { key: "January", label: "January", index: 1 },
    { key: "February", label: "February", index: 2 },
    { key: "March", label: "March", index: 3 },
    { key: "April", label: "April", index: 4 },
    { key: "May", label: "May", index: 5 },
    { key: "June", label: "June", index: 6 },
    { key: "July", label: "July", index: 7 },
    { key: "August", label: "August", index: 8 },
    { key: "September", label: "September", index: 9 },
    { key: "October", label: "October", index: 10 },
    { key: "November", label: "November", index: 11 },
    { key: "December", label: "December", index: 12 },
  ],
  ECOMMERCE_PLATFORM_NAMES: [
    { key: "Amazon", label: "Amazon" },
    { key: "Flipkart", label: "Flipkart" },
  ],
  AUDIENCE_TYPES: [
    { key: "B2B", label: "B2B" },
    { key: "B2C", label: "B2C" },
    { key: "BOTH", label: "Both" },
  ],
};