import React, { Component, useState } from "react";
import Select from "react-dropdown-select";
import { AppMonthPicker } from "./AppMonthPicker";
import AppUploadFile from "./AppUploadFile";
import AppUploadFileCroped from "./AppUploadFileCroped";

export class AppInput extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      appinput: React.createRef(),
    };
    this.state = {
      attrs: this.attrs,
      options: [],
      clearClicked: false,
    };
  }

  get attrs() {
    let props = Object.except(this.props, [
      "className",
      "style",
      "labelClass",
      "controlClass",
      "labelpos",
      "children",
      "type",
      `dispatch`,
      `setstore`,
      `initialconstruct`,
      `navigate`,
      "apis",
      `$l`,
    ]);
    let onInvalid = (e) => {
      // console.log("onInvalid",{target:e.target,e,validity:e.target.validity})
      // if(props.invalidMessage&&!e.target.validity.valid) {
      //   e.target.setCustomValidity(props.invalidMessage);
      // } else if(e.target.validity.valid) {
      //   e.target.setCustomValidity("");
      // }
    };
    props.onInvalid = onInvalid;
    return props;
  }

  get options() {
    let options = this.props.options;
    if (this.state.options.length) {
      return this.state.options;
    } else if (typeof options == "string") {
      console.log({ ...app.props.store[options] });
      return [{ key: "", lable: "ALL" }, ...(app.props.store[options] || [])];
    } else if (typeof options == "function") {
      let results = options(this.props);
      if (results instanceof Array && this.state.options.length == 0) {
        return results;
      } else if (results instanceof Promise && this.state.options.length == 0) {
        results.then((data) => {
          data instanceof Array && this.setState({ options: data });
        });
        return this.state.options;
      }
    } else if (options instanceof Array) {
      return options;
    } else {
      return this.state.options;
    }
  }

  componentDidMount() {
    window.AppInputComponent = this;
    this.refs.appinput.vnode = this;
    if (["select"].includes(this.props.type)) {
      let inputEle = this.refs.appinput.querySelector(
        ".react-dropdown-select>div+input"
      );
      // console.log({inputEle},this,this.props.name);
      if (inputEle) {
        let defaultValue = this.props.defaultValue || "";
        inputEle.setAttribute("type", "select");
        let value =
          defaultValue instanceof Array ? defaultValue[0] || "" : defaultValue;
        if (this.props.multi) {
          inputEle.dataset.invalue = defaultValue;
        } else {
          inputEle.dataset.invalue = defaultValue;
        }
        inputEle.parentElement.dataset.type = "select";
        inputEle.parentElement.dataset.value = value;
        inputEle.autoComplete = "off";
      }
      const clearButton = this.refs.select2?.select?.current?.querySelector(
        ".react-dropdown-select-clear"
      );
      if (clearButton && !clearButton.id && this.state.attrs.name) {
        clearButton.id = this.state.attrs.name;
      }
    }
  }

  componentDidUpdate() {
    if (
      this.state.attrs.value != this.attrs.value ||
      this.state.attrs.defaultValue != this.attrs.defaultValue ||
      this.state.attrs.checked != this.attrs.checked ||
      this.state.attrs.multi != this.attrs.multi ||
      this.state.attrs.required != this.attrs.required
    ) {
      this.setState({
        attrs: this.attrs,
      });
    }
  }

  setOption(values, attrs) {
    let inputEle = this.refs.appinput.querySelector(
      ".react-dropdown-select>div+input"
    );
    let value = values[0];
    let valkey = values.map((v) => v?.key).join(",");
    inputEle.dataset.invalue = valkey;
    inputEle.parentElement.dataset.type = "select";
    inputEle.parentElement.dataset.value = value;

    this.props.onChange && this.props.onChange(values, value);
  }

  setCheckVal(e) {
    // console.log(e.target.checked);
    this.props.onChange && this.props.onChange(e.target.checked);
  }

  setText(e) {
    if (this.props.pattern) {
      mixins.debounce(() => {
        if (!e.target.value.match(new RegExp(this.props.pattern))) {
          e.target.setCustomValidity(
            this.props.invalidmessage || "Invalid Text"
          );
          e.target.dataset.invalid = true;
        } else {
          e.target.setCustomValidity("");
          e.target.dataset.invalid = false;
        }
      });
    }
  }

  setTextarea(e) {
    if (this.props.pattern) {
      mixins.debounce(() => {
        if (!e.target.value.match(new RegExp(this.props.pattern))) {
          e.target.setCustomValidity(
            this.props.invalidmessage || "Invalid Text"
          );
          e.target.dataset.invalid = true;
        } else {
          e.target.setCustomValidity("");
          e.target.dataset.invalid = false;
        }
      });
    }
  }

  searchOptions(search) {
    if (search.state.searchResults.length == 0) {
      this.props.onSeach && this.props.onSeach(search.state.search, search);
    }
    // console.log("searchOptions",search.state.search,search);
  }

  clearSearchValue(search) {
    this.setState({ clearClicked: true }, () => {
      setTimeout(() => this.setState({ clearClicked: false }), 100);
    });
    this.props.clearSearchValue && this.props.clearSearchValue(search);
  }

  getInputEle() {
    return document.querySelector(`[name='appinput-${this.props.name}']>input`);
  }

  getInputValue() {
    return this.getInputEle()?.value;
  }
  render() {
    let { props, state } = this;
    let { attrs, clearClicked } = state;
    let {
      type,
      label,
      labelClass = "col-form-label",
      selectplaceholder = "Select",
      labelpos = "top",
      searchField = false,
    } = props;
    let isText = ["text"].includes(type);
    let isSelect = ["select"].includes(type);
    let isDate = ["date"].includes(type);
    let isMonth = ["month"].includes(type);
    let isTextarea = ["textarea"].includes(type);
    let isRadio = ["radio"].includes(type);
    let isCheckbox = ["checkbox"].includes(type);
    let isFile = ["file"].includes(type);
    let isFileCrop = ["fileCrop"].includes(type);
    let isFileCropModal = ["fileCropModal"].includes(type);
    let isLabel = ["label"].includes(type);
    let isButton = ["button"].includes(type);
    let isLink = ["link"].includes(type);
    let isIcon = ["icon"].includes(type);
    let isAmount = ["amount"].includes(type);

    let isOthers = !(
      isText ||
      isSelect ||
      isDate ||
      isTextarea ||
      isRadio ||
      isCheckbox ||
      isMonth ||
      isFile ||
      isFileCrop ||
      isFileCropModal ||
      isLabel ||
      isButton ||
      isLink ||
      isIcon ||
      isAmount
    );
    attrs.placeholder = isSelect
      ? attrs.placeholder || "Select"
      : attrs.placeholder || "Enter Here";
    attrs = Object.except(attrs, ["options"]);
    let value = attrs.defaultValue;
    if ([undefined, "", null].includes(value)) {
      value = this.props.defaultValue;
    }
    if (isSelect) {
      value = (this.options || []).find(
        (opt) => opt[attrs.valueField || "key"] == attrs.defaultValue
      );
      if (attrs.defaultValue instanceof Array) {
        value = this.options.filter((o) =>
          attrs.defaultValue.includes(o[attrs.valueField || "key"])
        );
      }
    }
    this.value = value;
    if (value != this.getInputValue() && this.getInputEle() && value) {
      this.getInputEle().value = value;
    }
    // if(isSelect) {
    //   console.log({attrs,value,defaultValue:attrs.defaultValue});
    // }
    if (isOthers) {
      // console.log({ attrs, value, defaultValue: attrs.defaultValue });
    }

    return (
      <div
        ref={"appinput"}
        className={"form-group relative " + (props?.className || "")}
        name={"appinput-" + props?.name || ""}
        style={props?.style || {}}
      >
        {labelpos == "top" && label && (
          <>
            {typeof label === "string" && (
              <label className={labelClass || ""} htmlFor={attrs.name}>
                {label}
                {attrs.required && <span className="text-danger">*</span>}
              </label>
            )}
            {typeof label !== "string" && label}
          </>
        )}
        {isLabel && <div>{props.children}</div>}
        {isSelect && attrs.multi && (
          <Select
            ref="select"
            id="select"
            multi={true}
            labelField={"label"}
            valueField={"key"}
            values={value instanceof Array ? value : (value && [value]) || []}
            options={this.options}
            name={attrs.name}
            className="h-full"
            aria-expanded="false"
            clearable={attrs.clearable || false}
            searchFn={(...args) => this.searchOptions(...args)}
            {...attrs}
            onChange={(values) => this.setOption(values)}
          />
        )}

        {isSelect && !attrs.multi && (
          <Select
            ref="select2"
            id="select"
            multi={false}
            labelField={"label"}
            valueField={"key"}
            values={value instanceof Array ? value : (value && [value]) || []}
            options={this.options}
            name={attrs.name}
            className="h-full"
            clearRenderer={attrs.clearRenderer}
            clearable={attrs.clearable || false}
            onDropdownOpen={() => !this.state?.clearClicked}
            onClearAll={(...args) => this.clearSearchValue(...args)}
            searchFn={(...args) => this.searchOptions(...args)}
            {...attrs}
            onChange={(values) => this.setOption(values)}
          />
        )}

        {isText &&
          (attrs.name === "user_id" && !attrs.searchField ? (
            <div className="input-group mb-3">
              <span className="input-group-text">
                {localStorage.getItem("currentClass")}
              </span>
              <input
                className={"form-control " + (props?.controlClass || "")}
                type="text"
                autoComplete="off"
                {...attrs}
              />
            </div>
          ) : (
            <input
              className={"form-control " + (props?.controlClass || "")}
              type="text"
              autoComplete="off"
              {...attrs}
              onChange={(...args) => this.setText(...args)}
            />
          ))}
        {isButton && (
          <button
            onClick={(...args) => props.onButtonClick(...args)}
            type="button"
          >
            {props.label}
          </button>
        )}
        {isLink && (
          <a
            id={props?.id}
            className="text-primary font-12 ml-1 cursor-pointer underline"
            ref={props?.ref}
            data-bs-toggle={props["data-bs-toggle"]}
            data-bs-target={props["data-bs-target"]}
            onClick={(...args) => props.onLinkClick(props?.value)}
          >
            {props.linkLabel}
          </a>
        )}
        {isIcon && (
          <img
            src={props.label}
            type="icon"
            onClick={(...args) => props.onClickOfIcon(...args)}
          />
        )}
        {isDate && (
          <AppDatePicker
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          ></AppDatePicker>
        )}
        {isMonth && (
          <AppMonthPicker
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          ></AppMonthPicker>
        )}
        {isFile && (
          <AppUploadFile
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          />
        )}
        {isFileCrop && (
          <>
            <label className="col-form-label label_for_preview">Preview</label>
            <AppUploadFileCrop
              className={"form-control " + (props?.controlClass || "")}
              type={type}
              onImageSelected={(...args) => this.onImageSelected(...args)}
              {...attrs}
            />
          </>
        )}
        {isFileCropModal && (
          <>
            <AppUploadFileCroped
              className={"form-control " + (props?.controlClass || "")}
              type={type}
              onImageSelected={(...args) => this.onImageSelected(...args)}
              {...attrs}
            />
          </>
        )}
        {isTextarea && (
          <textarea
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
            onChange={(...args) => this.setTextarea(...args)}
          ></textarea>
        )}
        {isAmount && (
          <div className="input-group mb-3">
            <p className="input-group-text amount-field">₹ </p>
            <input
              className={"form-control " + (props?.controlClass || "")}
              type="text"
              autoComplete="off"
              {...attrs}
            />
          </div>
        )}
        {isRadio && (
          <div className="radio">
            <label
              htmlFor={attrs.id}
              className={"cursor-pointer flex align-items-center"}
            >
              <input
                className={"me-2" + (props?.controlClass || "")}
                type={type}
                id={attrs.id}
                {...attrs}
              />
              {props?.value}
              {props.children}
            </label>
          </div>
        )}
        {isCheckbox && (
          <div className="checkbox">
            <label htmlFor={attrs.id} className="cursor-pointer">
              <input
                className="form-check-input mt-0 me-2"
                onChange={(e) => this.setCheckVal(e)}
                type={type}
                id={attrs.name}
                {...attrs}
              />
              {props.children}
            </label>
          </div>
        )}
        {isOthers && (
          <input
            className={"form-control " + (props?.controlClass || "")}
            type={type}
            {...attrs}
          />
        )}
        {labelpos != "top" && label && (
          <label className={labelClass || ""}>
            {label}
            {attrs.required && <span className="text-danger">*</span>}
          </label>
        )}
        {/* <span className="text-danger">error message here</span> */}
        {this.props.note && (
          <span className="note mt-1 d-block">{this.props.note}</span>
        )}
      </div>
    );
  }
}

export default connect(AppInput);
