import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";

export class ConfigPage extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      basic_config_fields: [
        {
          name: "package_name",
          label: "Name",
          type: "text",
          required: true,
        },
        {
          name: "thumbnail_file",
          label: "Thumbnail Image* (Max of 2 MB)",
          type: "file",
          fileType: "package",
          uploadType: "public",
          fileReturnType: "url",
          accept: "image/*",
          required: true,
          checkFileSize: true,
          maxsize: "2", //in mb
        },
        {
          name: "recommended_class",
          label: "Recommended Class",
          type: "select",
          multi: true,
          required: true,
        },
        {
          name: "category",
          label: "Category",
          type: "select",
          multi: true,
          onChange: (...args) => this.getSessions(...args),
          required: true,
        },
        {
          name: "package_description",
          label: "Package Desciption",
          type: "textarea",
          colMdspan: "8",
          required: true,
        },
      ],
      media_configuration: {
        media_images: [],
        media_videos: [""],
      },
      e_comm_configuration: [{}],
      session_config_fields: [
        {
          name: "session_id",
          label: "Session Name",
          type: "select",
          multi: true,
          required: true,
        },
      ],
    };
  }

  get has_edit() {
    return !!this.props.urlparams.package_id;
  }

  get has_view() {
    return !!this.props.urlparams.readonly;
  }

  addField(e, fieldname) {
    let { media_configuration } = this.state;
    media_configuration[fieldname] = [...media_configuration[fieldname], ""];
    this.setState({
      media_configuration,
    });
  }
  deleteField(e, index, fieldname) {
    e.preventDefault();
    const removeLocal = () => {
      let { media_configuration } = this.state;
      media_configuration[fieldname] = media_configuration[fieldname].filter(
        (v, i) => i != index
      );
      this.setState({
        media_configuration,
      });
    };
    removeLocal();
  }

  addEcommConfig(e) {
    let { e_comm_configuration } = this.state;
    e_comm_configuration = [...e_comm_configuration, ""];
    this.setState({
      e_comm_configuration,
    });
  }
  deleteEcommConfig(e, index) {
    e.preventDefault();
    const removeLocal = () => {
      let { e_comm_configuration } = this.state;
      e_comm_configuration = e_comm_configuration.filter((v, i) => i != index);
      this.setState({
        e_comm_configuration,
      });
    };
    removeLocal();
  }
  // ----------------------------------------------------------------
  componentDidMount() {
    if (this.props.urlparams.package_id) {
      this.props.api
        .apiGetPackageById({
          package_id: this.props.urlparams.package_id,
        })
        .then(({ data, total }) => {
          // console.log("apiGetPackageById: ", data);
          let updatedBasicFields = this.state.basic_config_fields.map(
            (field) => {
              return {
                ...field,
                defaultValue: data[field.name],
                disabled: this.has_view,
              };
            }
          );
          let updatedSessionConfigFields = this.state.session_config_fields.map(
            (field) => {
              return {
                ...field,
                defaultValue: data[field.name],
                disabled: this.has_view,
              };
            }
          );
          this.setState({
            basic_config_fields: updatedBasicFields,
            media_configuration: {
              media_images: [...data?.media_configuration?.media_images],
              media_videos:
                data?.media_configuration?.media_videos &&
                data?.media_configuration?.media_videos?.length !== 0
                  ? [...data?.media_configuration?.media_videos]
                  : [""],
            },
            e_comm_configuration: [...data?.e_comm_configuration],
            session_config_fields: updatedSessionConfigFields,
          });
        });
    }

    // GET CLASSES
    this.getClasses();

    // GET CATEGORY
    this.getCategory();
  }
  getClasses() {
    this.props.api.apiGetClasses().then(({ data, total }) => {
      data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
      let classlistOpts = data?.options("curriculum_id", "curriculum_grade");

      let updatedFields = this.state.basic_config_fields.map((field) => {
        if (field.name === "recommended_class") {
          return {
            ...field,
            options: classlistOpts,
          };
        } else {
          return { ...field };
        }
      });
      this.setState({
        basic_config_fields: updatedFields,
      });
    });
  }
  getCategory() {
    this.props.api.apiGetSubModules().then((res) => {
      let categoryOpts = res.data[0]?.records?.options(
        "sub_module_id",
        "sub_module_name"
      );

      let updatedFields = this.state.basic_config_fields.map((field) => {
        if (field.name === "category") {
          return {
            ...field,
            options: categoryOpts,
          };
        } else {
          return { ...field };
        }
      });
      this.setState({
        basic_config_fields: updatedFields,
      });
    });
  }

  getSessions(record) {
    console.log(record);
    let ids = record?.map((v) => v.sub_module_id).join();
    let payload = {
      sub_module_id: ids,
      audience_type: "B2C",
    };

    this.props.api.apiGetSessionsList(payload).then((res) => {
      let sessionOpts = res.data[0]?.records?.options(
        "session_id",
        "session_name"
      );

      let updatedFields = this.state.session_config_fields.map((field) => {
        if (field.name === "session_id") {
          return {
            ...field,
            options: sessionOpts,
          };
        } else {
          return { ...field };
        }
      });
      this.setState({
        session_config_fields: updatedFields,
      });
    });
  }
  submitForm() {
    let data = this.refs.form.form.current.getData();
    let parsedPayload = parseInput(data) || {};
    let payload = {
      ...parsedPayload,
    };
    console.log({ parsedPayload, payload });

    let attachmentIds = [];
    let thumbnailFileObj = {};
    let mediaImageContainers = document.querySelectorAll(
      "[name='appinput-media_configuration.media_images'] [data-attachment-id]"
    );
    let thumbnailAttachmentId = document.querySelector(
      "input[name='thumbnail_file']"
    ).value;

    if (mediaImageContainers)
      mediaImageContainers.forEach((item) =>
        attachmentIds.push({
          attachment_id: item?.dataset.attachmentId,
          url: item?.dataset.thumbnailUrl,
        })
      );

    if (thumbnailAttachmentId) {
      thumbnailFileObj.url = payload.thumbnail_file;
      thumbnailFileObj.attachment_id = thumbnailAttachmentId;
    }
    payload.media_configuration.media_images = attachmentIds;
    payload.thumbnail_file = thumbnailFileObj;
    console.log({ payload });

    if (this.has_edit) {
      let editDataPayload = { ...payload };
      this.props.api.apiUpdatePackage(editDataPayload).then((res) => {
        success("Package Updated Successfully");
        this.props.navigate("/businesstoconsumerconfig");
      });
    } else {
      this.props.api.apiAddPackage(payload).then((res) => {
        success("Package Added Successfully");
        this.props.navigate("/businesstoconsumerconfig");
      });
    }
  }
  getTitle() {
    return `B2C Package Configuration`;
  }
  getBreadCums() {
    let packagename = this.props.urlparams.package_name;
    if (this.props.urlparams.package_id && this.props.urlparams.readonly) {
      return `B2C Package Configuration / ${packagename} View Detail`;
    } else if (this.props.urlparams.package_id) {
      return `B2C Package Configuration / ${packagename} Edit Detail`;
    } else {
      return `B2C Package Configuration / Add Package`;
    }
  }
  render() {
    let { props, has_edit, has_view } = this;
    let {
      basic_config_fields,
      media_configuration,
      e_comm_configuration,
      session_config_fields,
    } = this.state;
    return (
      <>
        <LayoutWrapper
          title={this.getTitle()}
          back={this.getBreadCums()}
          backlink="/businesstoconsumerconfig"
        >
          <AppForm ref="form">
            {/* BASIC CONFIG */}
            <div className="config_card row mb-4">
              <div className="col-12">
                <h2 className="title">Basic Configuration</h2>
              </div>
              {basic_config_fields?.map((field, i) => (
                <div
                  className={`col-sm-6 col-md-${field?.colMdspan || "4"}`}
                  key={i}
                >
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>

            {/* MEDIA CONFIG */}
            <div className="config_card row mb-4">
              <div className="col-12">
                <h2 className="title">Media Configuration</h2>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-10">
                      <AppInput
                        name={`media_configuration.media_images`}
                        label={"Images (Max of 5 MB)"}
                        type="fileCropModal"
                        required={true}
                        accept={"image/*"}
                        fileType="package"
                        uploadType="public"
                        fileReturnType="url"
                        checkFileSize={true}
                        maxsize="5" //in mb
                        defaultValue={media_configuration.media_images}
                        {...(media_configuration?.media_images
                          ? {
                              defaultValue: media_configuration?.media_images,
                            }
                          : {})}
                        disabled={has_view}
                      ></AppInput>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    {media_configuration?.media_videos?.map((field, i) => (
                      <>
                        <div className="col-10" key={i}>
                          <AppInput
                            name={`media_configuration.media_videos.${i}`}
                            label={"Videos (Max of 5 MB)"}
                            type="text"
                            defaultValue={field}
                            disabled={has_view}
                          ></AppInput>
                        </div>
                        <div className="col-2 d-flex">
                          {i != 0 && !props.urlparams.readonly && (
                            <button
                              onClick={(e) =>
                                this.deleteField(e, i, "media_videos")
                              }
                              className="btn bg-red-500 m-auto mb-2 text-white"
                            >
                              <i className="fa fa-trash-o text-error m-0" />
                            </button>
                          )}
                        </div>
                      </>
                    ))}
                    <div>
                      {!props.urlparams.readonly && (
                        <button
                          className={Object.className({
                            "btn bg-transparent flex gap-2 text-primary": true,
                            "d-none":
                              media_configuration?.media_videos?.length === 5,
                          })}
                          type="button"
                          onClick={(e) => this.addField(e, "media_videos")}
                        >
                          <i className="fa fa-plus m-auto" />
                          <span className="font-bold">Add More</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* E-COMM CONFIG */}
            <div className="config_card row mb-4">
              <div className="col-12">
                <h2 className="title">E-Comm Configuration</h2>
              </div>
              <div className="row">
                {e_comm_configuration?.map((field, i) => (
                  <>
                    <div className="col-12 col-md-3" key={i}>
                      <AppInput
                        name={`e_comm_configuration.${i}.platform_name`}
                        label="E-commerce Platform Name"
                        type="select"
                        options={_enum.ECOMMERCE_PLATFORM_NAMES}
                        required={true}
                        defaultValue={field?.platform_name || ""}
                        disabled={has_view}
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3" key={i}>
                      <AppInput
                        name={`e_comm_configuration.${i}.product_url`}
                        label="Product URL"
                        type="text"
                        defaultValue={field?.product_url || ""}
                        disabled={has_view}
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-2" key={i}>
                      <AppInput
                        name={`e_comm_configuration.${i}.price`}
                        label="Price"
                        type="number"
                        defaultValue={field?.price || ""}
                        disabled={has_view}
                      ></AppInput>
                    </div>
                    <div
                      className="col-12 col-md-2 d-flex delete_icon_column"
                      key={i}
                    >
                      <AppInput
                        name={`e_comm_configuration.${i}.discount`}
                        label="Discount"
                        type="number"
                        defaultValue={field?.discount || ""}
                        disabled={has_view}
                      ></AppInput>
                      {i != 0 && !props.urlparams.readonly && (
                        <button
                          onClick={(e) => this.deleteEcommConfig(e, i)}
                          className="btn bg-red-500 m-auto mx-0 mb-2 text-white"
                        >
                          <i className="fa fa-trash-o text-error m-0" />
                        </button>
                      )}
                    </div>
                  </>
                ))}
                <div>
                  {!props.urlparams.readonly && (
                    <button
                      className="btn bg-transparent flex gap-2 text-primary"
                      type="button"
                      onClick={(e) => this.addEcommConfig(e)}
                    >
                      <i className="fa fa-plus m-auto" />
                      <span className="font-bold">Add More</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* SESSION CONFIG */}
            <div className="config_card row mb-4">
              <div className="col-12">
                <h2 className="title">Session Configuration</h2>
              </div>
              {session_config_fields?.map((field, i) => (
                <div
                  className={`col-sm-6 col-md-${field?.colMdspan || "10"}`}
                  key={i}
                >
                  <AppInput {...field}></AppInput>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={() =>
                    this.props.navigate(`/businesstoconsumerconfig`)
                  }
                  className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                >
                  Cancel
                </button>
                <button
                  className={Object.className({
                    "btn btn-success btn-block": true,
                    "d-none": has_view,
                  })}
                  onClick={() => this.submitForm()}
                >
                  {has_edit ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </AppForm>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(ConfigPage);
