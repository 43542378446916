import { Component } from "react";
import LayoutWrapper from "../schoolmanagement/LayoutWrapper";
import ContentCard from "./ContentCard";

export class Curriculum extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Curriculum");
    this.state = {
      data: {},
      rowcount: 0,
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      schoolId: window.store.getState().root.school_id,
      search: {
        limit: 10,
        page: 1,
        order_by: "class_level",
        order_direction: "ASC",
      },
      loader: false,
      MAP_LEVEL_GRADE: {
        A: "I",
        B: "II",
        C: "III",
        D: "IV",
        0: "V",
        1: "VI",
        2: "VII",
        3: "VIII",
        4: "IX",
        5: "X",
        6: "XI",
        7: "XII",
      },
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    let profileData = JSON.parse(localStorage.getItem("profile-data"));
    console.log({ profileData });

    // this.setState({ loader: false, data: profileData });

    this.props.api
      .apiGetStudentClassById({ class_id: profileData?.class_id })
      .then((response) => {
        let data = response.data;
        console.log("curriculum data", data);
        this.setState({
          data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
      });
  }

  onSearch(data) {
    this.fetchList(data);
  }

  handleSubmit(e) {
    e.preventDefault();
    let formData = e.target.getData();
    console.log("formData", formData);
    this.setState({ loader: true });
  }

  changeLevel(value) {
    console.log({ value });
    let grade = this.state.MAP_LEVEL_GRADE[value?.curriculum?.curriculum_level];
    this.props.navigate(
      `/curriculum/pillarspage?grade=${grade}&level=${value?.curriculum?.curriculum_level}&curriculum_id=${value?.curriculum?.curriculum_id}&class_id=${value.class_id}`
    );
    // localStorage.setItem("module_id", value.module_id);
    // localStorage.setItem("curriculum_id", value.curriculum_id);
    // let grade = _enum.MAP_LEVEL_GRADE[value.curriculum_level];
    // if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
    //   this.props.navigate(
    //     `/curriculum/pillarspage?grade=${grade}&level=${value.curriculum_level}&curriculum_id=${value.curriculum_id}&class_id=${value.class_id}`
    //   );
    // } else {
    //   this.props.navigate(
    //     `/curriculum/pillarspage?grade=${grade}&level=${value.curriculum_level}&curriculum_id=${value.curriculum_id}`
    //   );
    // }
  }

  // updateFields(fields) {
  //   fields.find((o) => o.name === "school_id").options =
  //     this.state.schools.options("school_id", "school_name");
  //   return fields;
  // }

  render() {
    let { data } = this.state;
    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title={"Hello VI- Table"} back={`Curriculum`}>
          <div className="row">
            <div className="col"></div>
          </div>
          <Row className="mx-0 mb-3 mt-4 g-3">
            <Col className="col-12 col-lg-4 col-xxl-3">
              <a onClick={() => this.changeLevel(data)}>
                <ContentCard
                  grade={data?.curriculum?.curriculum_grade}
                  level={data?.curriculum?.curriculum_level}
                  content="Major focus areas: Experiential learning, Brain Power Enrichment, Behavioral and communication skills."
                />
              </a>
            </Col>
          </Row>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(Curriculum);
